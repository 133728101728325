import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import instagram from '../assets/images/brands/instagram.webp'
import '../assets/css/styles.css';
import * as Icon from 'react-feather';

export default function Contact() {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (event) => {
        setIsSubmitting(true);
    };

    return (
        <>
            {/* Start Contact  */}
            <section className="section bg-light" id="contact">
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-3 text-blue">Contacto</h4>
                                <p className="text-muted para-desc mb-0 mx-auto">Estoy aquí para simplificar el proceso legal y proteger tus derechos. ¡No dudes en comunicarte y dar el primer paso hacia la solución que mereces!</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                        <Col lg={8} md={6} className="order-md-2 order-1 mt-4 pt-2">
                            <div className="p-4 rounded shadow bg-white">
                                <form method="POST" name="myForm" action="./backend/index.php" onSubmit={handleSubmit}>
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-4">
                                                <input name="name" id="name" type="text" className="form-control" placeholder="Nombre:" required/>
                                            </div>
                                        </Col>

                                        <Col md={6} >
                                            <div className="mb-4">
                                                <input name="email" id="email" type="email" className="form-control" placeholder="Email:" required/>
                                            </div>
                                        </Col>

                                        <div className="col-12">
                                            <div className="mb-4">
                                                <input name="subject" id="subject" className="form-control" placeholder="Asunto:" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-4">
                                                <textarea name="comments" id="comments" rows={4} className="form-control" placeholder="Mensaje:" required></textarea>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-12 text-end">
                                            <button type="submit" id="submit" name="send" className="btn btn-primary fw-600" disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    "Contactar"
                                                )}
                                            </button>
                                        </div>
                                    </Row>
                                </form>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
                            <div className="me-lg-4">
                                <div className="d-flex text-blue">
                                    <div className="icons text-center mx-auto">
                                        <Icon.Phone className="icons" />
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2 text-blue">Teléfono</h5>
                                        <Link to="tel:+54 9 11 2756-8433" className="text-muted">+54 9 11 2756-8433</Link>
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="icons text-blue text-center mx-auto">
                                        <Icon.Mail className="icons" />
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2 text-blue">Email</h5>
                                        <Link to="mailto:El MAIL" className="text-muted">info@doctortransito.com.ar</Link>
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="icons text-center text-blue mx-auto">
                                        <Icon.Users className="icons" />
                                    </div>

                                    <div className="flex-1 ms-3">
                                        <h5 className="mb-2 text-blue">Redes sociales</h5>
                                        <a href='https://www.instagram.com/doctortransitoar' rel="noopener noreferrer" target="_blank">
                                            <div className="logo-instagram">
                                                <img src={instagram} className="img-cover" alt="Logo de Instagram"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* End Contact  */}
        </>
    )
}