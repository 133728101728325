import React, { useState, useEffect } from 'react';
import {
    Col, Container, Row, Nav,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    NavItem, Modal, Button, ModalFooter, ModalBody, ModalHeader
} from "reactstrap";
import * as Icon from 'react-feather';
// import Typed from 'react-typed';
import Typed from "typed.js";
import { Link } from 'react-scroll';

import Logodark from "../../assets/images/logoDR.png";
import CalleImg from "../../assets/images/bg/webDiTullio4.jpeg";
import Contact from "../../component/Contact";
import person1 from "../../assets/images/personal/webDiTullio9.webp"
import whatsapp from '../../assets/images/brands/whatsapp.webp'

import About from "../../assets/images/personal/juan3.jpeg";
import about1 from "../../assets/images/portfolio/webDiTullio5.jpg"
import about2 from "../../assets/images/portfolio/webDiTullio6.jpg"
import about4 from "../../assets/images/portfolio/webDiTullio2.jpg"

import '../../assets/css/styles.css';

export default function Personal() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [arrow, setArrow] = useState(false);
    const [arrow2, setArrow2] = useState(false);
    const [isOpen, setMenu] = useState(true);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);

    const toggle = () => setModal(!modal);

    const toggle2 = () => setModal2(!modal2);

    const handleScroll = () => {
        if (
            document.body.scrollTop >= 100 ||
            document.documentElement.scrollTop >= 100
        ) {
            setArrow(true);
            setArrow2(true);
        } else {
            setArrow(false);
            setArrow2(false);
        }
    };

    const toggleMenu = () => {
        setMenu(!isOpen)
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);


        const typed = new Typed('#typed', {
            strings: [
                "<b>Juan Pablo di Tullio.</b>",
                "<b>abogado.</b>",
            ],
            backDelay: 2000,
            loop: true,
            startDelay: 300,
            typeSpeed: 100,
            backSpeed: 100,
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            typed.destroy();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        const handleResizeMob = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        handleResizeMob();

        window.addEventListener('resize', handleResizeMob);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('resize', handleResizeMob);
        };
    }, []);

    const textSizeTitle = windowWidth > 768 && windowWidth <= 991 ? 'display-5' : 'display-4';

    const textSizeSubtitle = windowWidth > 768 && windowWidth <= 991 ? 'home-text-subtitle' : 'heading sub-heading';

    return (
        <>
            <nav id="navbar" className="navbar navbar-expand-lg fixed-top sticky bg-white rounded-bottom-4">
                <Container>
                    <NavbarBrand className="navbar-brand" href="/">
                        <div class="logo mt-2 mb-2">
                            <img src={Logodark} className="img-logo" alt="Logo de DoctorTransito" />
                        </div>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleMenu}>
                        <Icon.Menu />
                    </NavbarToggler>

                    <Collapse className={`navbar-collapse ${isOpen === true ? 'hidden' : 'show'}`} id="navbarSupportedContent">
                        <Nav className="navbar-nav ms-auto mb-2 mb-lg-0" id="navbar-navlist">
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="home">Inicio</Link>
                            </NavItem>
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="service">¿Cómo Funciona?</Link>
                            </NavItem>
                            <NavItem>
                                <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="about">¿Quién soy?</Link>
                            </NavItem>
                        </Nav>

                        <ul className="list-inline menu-social mb-0 ps-lg-4 ms-2">
                            <li className="list-inline-item">
                                <Link to="contact" spy={true} smooth={true} duration={500} className="text-primary d-flex align-items-center fw-600" style={{ cursor: 'pointer' }}><span className="btn btn-sm btn-icon btn-pills btn-primary me-2"><Icon.Mail className="icons" /></span>Contactar</Link>
                            </li>
                        </ul>
                    </Collapse>
                </Container>
            </nav>
            {/* HOME START */}
            <section className="bg-half-170 d-table w-100 bg-light pb-0 overflow-hidden" style={{ background: `url(${CalleImg}) top` }} id="home">
                <div className="bg-overlay"></div>
                <Container>
                    <Row className="align-items-center position-relative" style={{ zIndex: 1 }}>
                        <Col lg={7} md={6}>

                            <div className="title-heading ">
                                <h4 className={`display-4 fw-bold text-white home-text ${textSizeTitle}`}>¡Tu aliado legal en caso de accidentes de tránsito!</h4>
                                <p className={`mb-3 text-white home-text ${textSizeSubtitle}`}>Soy {" "}
                                    <span id="typed" className="typewrite position-relative text-type-element"></span>
                                </p>
                                <p className="text-light para-desc mb-0 home-text">Abogado especializado en accidentes de tránsito listo para luchar por tus derechos y obtener la máxima indemnización para vos.</p>

                                <div className="mt-4 home-text">
                                    <Link to="contact" spy={true} smooth={true} duration={500} className="btn btn-primary font-family-base fw-600">Contactar</Link>
                                </div>
                            </div>
                        </Col>

                        <Col lg={5} md={6} sm={8} className="mt-4 pt-2 offset-sm-2 offset-md-0 mt-sm-0 pt-sm-0">
                            <div className="position-relative">
                                <img src={person1} className="img-fluid" alt="Hombre con el pulgar arriba" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Start service  */}
            <section className="section bg-light" id="service">
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center pb-2">
                                <h5 className="title mb-3">¿Cómo funciona?</h5>
                            </div>
                        </div>
                    </div>
                    {isMobile ? (
                        <Row>

                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden">
                                    <img src={about1} class="card-img-top img-fluid" alt="Hombre viendo su auto enojado y hablando por telefono"></img>
                                    <div className="content p-4">
                                        <div className='d-flex flex-row align-items-center'>
                                            <Icon.Phone className="text-blue mr-3" />
                                            <h5 className='mb-0'>Cuentame lo sucedido</h5>
                                        </div>
                                        <p className="text-muted mt-3 text-justify">Comenzaré escuchando tu historia para comprender correctamente los detalles de tu accidente y las lesiones que has sufrido.
                                        </p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={4} md={6} className="mt-4 pt-2">
                                <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden">
                                    <img src={about4} class="card-img-top img-fluid" alt="Abogado por firmar un contrato"></img>
                                    <div className="content p-4">
                                        <div className='d-flex flex-row align-items-center'>
                                            <Icon.PenTool className="text-blue mr-3" />
                                            <h5 className='mb-0'>Diseñaré una estrategia</h5>
                                        </div>
                                        <p className="text-muted mt-3 text-justify">Desarrollaré un plan estratégico para tu caso contemplando cada aspecto del incidente para asegurar que el reclamo de los daños sea el correspondiente.
                                        </p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={4} md={12} className="mt-4 pt-2">
                                <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden">
                                    <img src={about2} class="card-img-top img-fluid" alt="Hombre hablando por telefono mientras maneja"></img>
                                    <div className="content p-4">
                                        <div className='d-flex flex-row align-items-center'>
                                            <Icon.CheckCircle className="text-blue mr-3" />
                                            <h5 className='mb-0'>Resolveré tu problema</h5>
                                        </div>
                                        <p className="text-muted mt-3 text-justify">Me comprometo a defender tus derechos para obtener la mejor compensación en el menor tiempo posible, brindándote la tranquilidad que necesitas.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    ) : (

                        <Row>
                            <Col md={6} lg={8} className="mt-4 pt-2">
                                <div className="card h-100 features d-flex flex-column justify-content-center feature-primary feature-clean feature-transition border-0 shadow2 rounded-start-5 overflow-hidden">
                                    <div className="content p-4">
                                        <div className='d-flex flex-row align-items-center'>
                                            <Icon.Phone className="text-blue mr-3" />
                                            <h5 className='mb-0'>Cuéntame lo sucedido</h5>
                                        </div>
                                        <p className="text-muted mt-3 text-justify">Comenzaré escuchando tu historia para comprender correctamente los detalles de tu accidente y las lesiones que has sufrido.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6} lg={4} className="mt-4 pt-2">
                                <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden">
                                    <img src={about1} class="img-cover" alt="Hombre viendo su auto enojado y hablando por telefono"></img>
                                </div>
                            </Col>

                            <Col md={6} lg={4} className="mt-4 pt-2">
                                <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden">
                                    <img src={about4} class="img-cover" alt="Abogado por firmar un contrato"></img>
                                </div>
                            </Col>

                            <Col md={6} lg={8} className="mt-4 pt-2">
                                <div className="card h-100 features d-flex flex-column justify-content-center feature-primary feature-clean feature-transition border-0 shadow2 rounded-end-5 overflow-hidden">
                                    <div className="content p-4">
                                        <div className='d-flex flex-row align-items-center'>
                                            <Icon.PenTool className="text-blue mr-3" />
                                            <h5 className='mb-0'>Diseñaré una estrategia</h5>
                                        </div>
                                        <p className="text-muted mt-3 text-justify">Desarrollaré un plan estratégico para tu caso contemplando cada aspecto del incidente para asegurar que el reclamo de los daños sea el correspondiente.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6} lg={8} className="mt-4 pt-2">
                                <div className="card h-100 features d-flex flex-column justify-content-center feature-primary feature-clean feature-transition border-0 shadow2 rounded-start-5 overflow-hidden">
                                    <div className="content p-4">
                                        <div className='d-flex flex-row align-items-center'>
                                            <Icon.CheckCircle className="text-blue mr-3" />
                                            <h5 className='mb-0'>Resolveré tu problema</h5>
                                        </div>
                                        <p className="text-muted mt-3 text-justify">Me comprometo a defender tus derechos para obtener la mejor compensación en el menor tiempo posible, brindándote la tranquilidad que necesitas.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6} lg={4} className="mt-4 pt-2">
                                <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden">
                                    <img src={about2} class="img-cover" alt="Hombre hablando por telefono mientras maneja"></img>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section>

            <section className="section2" id="about">
                {isMobile ? (
                    <Container>
                        <div className="align-items-center">

                            <div className="col-lg-7 col-md-12 mt-md-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="ms-lg-5">
                                    <div>
                                        <h4 className="title mb-3 fs-4 text-center">¿Quién soy?</h4>
                                        <p className="text-muted mb-5 fs-5 text-justify"> Soy el Dr. Juan Pablo di Tullio, abogado especializado en la defensa de víctimas de accidentes de tránsito con más de 15 años de experiencia en el Área de Seguros.<br></br>
                                            Junto a mi equipo tenemos el compromiso de ser tu aliado profesional para asegurar tu bienestar y la compensación que mereces  a través de soluciones efectivas.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-8">
                                <img src={About} className="rounded-5 shadow img-fluid offset-md-3 offset-lg-0 mb-lg-0" alt="Dr. Juan Pablo di Tullio abogado especialista en accidentes de transito" />
                            </div>

                            <Row>
                                <Col sm={12} className="mt-4 mb-2 pt-2">
                                    <div className="content mt-4">
                                        <h5 className="fs-4 text-dark">¿Qué me diferencia?</h5>
                                        <p className="text-muted mt-3 fs-5 text-justify">Mi enfoque ágil y personalizado a fin de lograr la pronta indemnización, priorizando tu tranquilidad y satisfacción total.

                                        </p>
                                    </div>
                                </Col>
                            </Row>

                            <div className="content col-md-5 p-4 pt-0 mt-4 mb-4">
                                <p className="text-muted text-center fst-italic fw-bolder display-5 m-0">"Si el plan A</p>
                                <p className="text-dark text-center fst-italic fw-bolder display-5 m-0">no funciona</p>
                                <p className="text-muted text-center fst-italic fw-bolder display-5 m-0">el abecedario</p>
                                <p className="text-muted text-center fst-italic fw-bolder display-5 m-0">tiene 26 letras más"</p>
                            </div>

                            <div className="content col-sm-12 pt-2">
                                <h5 className="fs-4 text-dark lh-sm">¿Que hacer en caso de accidente de tránsito?</h5>
                                <p className="text-muted mt-3 fs-5 text-justify">En primer lugar si hay lesionados dar inmediata intervención al SAME y/o a la Policía local. A continuación detallamos la documentación que debe tener para realizar el reclamo:
                                </p>
                            </div>

                            <Row>

                                <Col md={6} className="mt-4">
                                    <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden" type="button" onClick={toggle}>
                                        <div className="content p-4">
                                            <div className='d-flex flex-col align-items-center justify-content-center'>
                                                <Icon.User className="text-blue mr-3" />
                                                <p className='mb-0 fs-3 fw-bolder text-blue'>Datos del cliente</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={6} className="mt-4 pt-2 pb-5">
                                    <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden" type="button" onClick={toggle2}>
                                        <div className="content p-4">
                                            <div className='d-flex flex-row align-items-center justify-content-center'>
                                                <Icon.User className="text-blue mr-3" />
                                                <p className='mb-0 fs-3 fw-bolder text-blue'>Datos del tercero</p>
                                            </div>
                                        </div>

                                    </div>
                                </Col>

                                <div>
                                    <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle}><p className='fs-4 fw-bold m-0'>Datos del cliente</p></ModalHeader>
                                        <ModalBody>
                                            <ul className='ps-4'>
                                                <li>DNI frente y dorso.</li>
                                                <li>Licencia frente y dorso.</li>
                                                <li>Cédula de titularidad vehiculo frente y dorso.</li>
                                                <li>Certificado de seguro y denuncia realizada.</li>
                                                <li>Fotos del rodado (de los cuatro ángulos, que se vean los daños y la patente).</li>
                                                <li>Constancias médicas de lesiones (si las hay).</li>
                                                <li>Presupuesto de reparación.</li>
                                                <li>Relato de los hechos con indicación de calles, sentido, condiciones climáticas y horario.</li>
                                                <li>Denuncia policial (si la hay).</li>
                                                <li>Datos de testigos del accidente (nombre y apellido, DNI, domicilio y teléfono).</li>
                                                <li>Fotos del lugar del accidente (si las hay).</li>
                                            </ul>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="blue" className='fw-bolder' onClick={() => { toggle2(); toggle(); }}>
                                                Datos del tercero
                                            </Button>{' '}
                                            <Button color="secondary" className='fw-bolder' onClick={toggle}>
                                                Cerrar
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>

                                <div>
                                    <Modal isOpen={modal2} toggle={toggle2}>
                                        <ModalHeader toggle={toggle2}><p className='fs-4 fw-bold m-0'>Datos del tercero</p></ModalHeader>
                                        <ModalBody>
                                            <ul className='ps-4'>
                                                <li>DNI frente y dorso.</li>
                                                <li>Licencia frente y dorso.</li>
                                                <li>Cédula de titularidad frente y dorso.</li>
                                                <li>Certificado de seguro.</li>
                                            </ul>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="blue" className='fw-bolder' onClick={() => { toggle2(); toggle(); }}>
                                                Datos del cliente
                                            </Button>{' '}
                                            <Button color="secondary" className='fw-bolder' onClick={toggle2}>
                                                Cerrar
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>

                            </Row>


                        </div>
                    </Container>
                ) : (
                    <Container>
                        <div className="row align-items-center">


                            <div className="col-md-12 col-lg-7 mt-4 mt-sm-0 pt-2 p-5 pb-2 pt-sm-0">
                                <div className="">
                                    <div>
                                        <h4 className="title mb-3 fs-3">¿Quién soy?</h4>
                                        <p className="text-muted mb-0 fs-4 text-justify"> Soy el Dr. Juan Pablo di Tullio, abogado especializado en la defensa de víctimas de accidentes de tránsito con más de 15 años de experiencia en el Área de Seguros.<br></br>
                                            Junto a mi equipo tenemos el compromiso de ser tu aliado profesional para asegurar tu bienestar y la compensación que mereces  a través de soluciones efectivas.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="content col-md-12 p-md-4 col-lg-5 p-lg-5">
                                <p className="text-muted  text-center fst-italic fw-bolder display-5 m-0">"Si el plan A</p>
                                <p className="text-dark text-center fst-italic fw-bolder display-5 m-0">no funciona</p>
                                <p className="text-muted text-center fst-italic fw-bolder display-5 m-0">el abecedario</p>
                                <p className="text-muted text-center fst-italic fw-bolder display-5 m-0">tiene 26 letras más"</p>
                            </div>

                            <div className="col-lg-4 col-md-5 p-md-4 p-lg-5">
                                <img src={About} className="rounded-5 shadow img-fluid offset-lg-0 mb-sm-5 mb-md-0" alt="Dr. Juan Pablo di Tullio abogado especialista en accidentes de transito" />
                            </div>

                            <div className="content col-md-7 col-lg-8 p-md-4 p-lg-5">
                                <h5 className="fs-3 text-dark">¿Qué me diferencia?</h5>
                                <p className="text-muted mt-3 fs-4 text-justify">Mi enfoque ágil y personalizado a fin de lograr la pronta indemnización, priorizando tu tranquilidad y satisfacción total.

                                </p>
                            </div>

                            <div className="content col-md-12 p-md-4 p-lg-5 pt-lg-3 pb-2">
                                <h5 className="fs-3 text-dark">¿Que hacer en caso de accidente de tránsito?</h5>
                                <p className="text-muted mt-3 fs-4 text-justify">En primer lugar si hay lesionados dar inmediata intervención al SAME y/o a la Policía local. A continuación detallamos la documentación que debe tener para realizar el reclamo:
                                </p>
                            </div>

                            <Row>

                                <Col md={12} lg={6} className="mb-lg-4 pt-2 p-md-4 pt-md-0 p-lg-5 pt-lg-0">
                                    <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden scale-hover2" type="button" onClick={toggle}>
                                        <div className="content p-4">
                                            <div className='d-flex flex-row align-items-center justify-content-center'>
                                                <Icon.User className="text-blue mr-3" />
                                                <p className='mb-0 fs-3 fw-bolder text-blue'>Datos del cliente</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={12} lg={6} className="mb-md-4 mb-lg-4 pt-2 p-md-4 p-lg-5 pt-lg-0">
                                    <div className="card h-100 features feature-primary feature-clean feature-transition border-0 shadow2 rounded-md overflow-hidden scale-hover2" type="button" onClick={toggle2}>
                                        <div className="content p-4">
                                            <div className='d-flex flex-row align-items-center justify-content-center'>
                                                <Icon.User className="text-blue mr-3" />
                                                <p className='mb-0 fs-3 fw-bolder text-blue'>Datos del tercero</p>
                                            </div>
                                        </div>

                                    </div>
                                </Col>

                                <div>
                                    <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle}><p className='fs-4 fw-bold m-0'>Datos del cliente</p></ModalHeader>
                                        <ModalBody>
                                            <ul>
                                                <li>DNI frente y dorso.</li>
                                                <li>Licencia frente y dorso.</li>
                                                <li>Cédula de titularidad vehiculo frente y dorso.</li>
                                                <li>Certificado de seguro y denuncia realizada.</li>
                                                <li>Fotos del rodado (de los cuatro ángulos, que se vean los daños y la patente).</li>
                                                <li>Constancias médicas de lesiones (si las hay).</li>
                                                <li>Presupuesto de reparación.</li>
                                                <li>Relato de los hechos con indicación de calles, sentido, condiciones climáticas y horario.</li>
                                                <li>Denuncia policial (si la hay).</li>
                                                <li>Datos de testigos del accidente (nombre y apellido, DNI, domicilio y teléfono).</li>
                                                <li>Fotos del lugar del accidente (si las hay).</li>
                                            </ul>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="blue" className='fw-bolder' onClick={() => { toggle2(); toggle(); }}>
                                                Datos del tercero
                                            </Button>{' '}
                                            <Button color="secondary" className='fw-bolder' onClick={toggle}>
                                                Cerrar
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>

                                <div>
                                    <Modal isOpen={modal2} toggle={toggle2}>
                                        <ModalHeader toggle={toggle2}><p className='fs-4 fw-bold m-0'>Datos del tercero</p></ModalHeader>
                                        <ModalBody>
                                            <ul>
                                                <li>DNI frente y dorso.</li>
                                                <li>Licencia frente y dorso.</li>
                                                <li>Cédula de titularidad frente y dorso.</li>
                                                <li>Certificado de seguro.</li>
                                            </ul>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="blue" className='fw-bolder' onClick={() => { toggle2(); toggle(); }}>
                                                Datos del cliente
                                            </Button>{' '}
                                            <Button color="secondary" className='fw-bolder' onClick={toggle2}>
                                                Cerrar
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>

                            </Row>

                        </div>
                    </Container>)}

            </section>
            <Contact />
            <footer className="bg-white footer-bar">
                <div className="footer-py-30">
                    <div className="container text-center">
                        <div className="row align-items-center justify-content-center">
                            <p className="mb-0 text-gray">© {(new Date().getFullYear())}{" "} DoctorTransito</p>
                        </div>
                    </div>

                </div>
            </footer>
            {/* End Footer */}

            <Link to="home" style={{ display: arrow === true ? 'block' : 'none' }} id="back-to-top" className="back-to-top rounded-pill fs-5"><Icon.ArrowUp className="fea icon-sm icons align-middle" /></Link>

            <a href='https://api.whatsapp.com/send?phone=+5491127568433&text=Tu%20mensaje%20personalizado.' rel="noopener noreferrer" target="_blank">
                <div style={{ display: arrow2 === true ? 'block' : 'none', cursor: 'pointer' }} className="logo-whatsapp scale-hover whatsApp-static">
                    <img src={whatsapp} className="img-cover" alt="logo verde cuadrado de WhatsApp"></img>
                </div>
            </a>
        </>
    )
};
